@import-normalize;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/** Colors */
$primary-blue: #072642;
$secondary-blue: #3073c2;
$primary-faded-blue: #1b3852;
$primary-teal: #5cadab;
$primary-white: #f5f5f5;
$secondary-white: #fff;
$primary-gold: #d48a36;
$primary-grey: #b4bdc6;
$text-on-blue-primary: #51677b;
$text-on-blue-secondary: #4f6478;
$card-border: #f9f9f9;

* {
    font-family: "Poppins", sans-serif;;
}

body {
    margin: 0;
}

p {
    margin-block-start: 0;
    margin-block-end: 0;
}

label, button {
    cursor: pointer;
}

p {
    cursor: default;
}

input {
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-color: $primary-faded-blue;
    color: $primary-blue;
}

.App {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $primary-blue;
}

.dynamic-form-logo {
    height: 50px;
    margin-bottom: 40px;
}

.dynamic-form-toast {
    color: $primary-blue;
    max-width: 300px;
    background-color: $primary-white;
    padding: 20px;
    border-radius: 10px;
    border-color: $card-border;
    box-shadow: 0 5px 30px 0 rgba(0,0,0,0.21);
    margin-bottom: 10px;
}

.dynamic-form {
    display: flex;
    flex-direction: column;
    background-color: $primary-white;
    padding: 20px;
    border-radius: 10px;
    border-color: $card-border;
    box-shadow: 0 5px 30px 0 rgba(0,0,0,0.21);
    
    .dynamic-form-title {
        color: $primary-blue;
        font-size: 20px;
        margin-bottom: 20px;
        font-weight: 500;
    }

    .dynamic-form-description {
        color: $primary-faded-blue;
        font-size: 14px;
        margin-bottom: 30px;
        width: 300px;
    }

    .dynamic-form-message {
        color: $primary-gold;
        font-size: 14px;
        margin-bottom: 10px;
    }

    .dynamic-form-fields {
        margin-bottom: 10px;
    }

    .dynamic-form-field {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }

    .dynamic-form-info {
        color: $primary-grey;
        font-size: 12px;
        text-align: center;
        padding-bottom: 10px;
    }

    .dynamic-form-submit {
        background-color: $primary-teal;
        color: $primary-white;
        border: none;
        padding: 8px 24px;
        border-radius: 6px;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .dynamic-form-alternate {
        background-color: unset !important;
        color: $primary-blue;
        border: none;
        margin-top: 10px;
        border-radius: 6px;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
}
